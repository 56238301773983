import logo from './logo.svg';
import './App.css';
import Privacy from './Privacy';

function App() {
  return (
    <Privacy/>
  );
}

export default App;
